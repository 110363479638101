import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import OrderDetails from '../../../../Orders/Details';
import { formatDateWithHour } from '../../../../../helpers/formatData';

function ReservationStocks({ productId, openDialogReservations, handleCloseDialogReservations }) {
  const [reservationsStock, setReservationsStock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const getReservationsStock = async () => {
      try {
        setLoading(true);
        const response = await Axios.get('/orders/reserved-stocks', {
          params: { productId },
        });
        setReservationsStock(response.data.reservedStocks);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          error.response.data.message || 'Algum erro ocorreu ao buscar as reservas de estoque',
        );
      }
    };
    getReservationsStock();
  }, []);

  const closeOrderDetails = useCallback(() => setOpenOrderDetails(false), []);

  const handleSelectOrder = async (reservation) => {
    if (reservation && reservation.sourceId) {
      try {
        setLoading(true);

        const response = await Axios.get(
          `/${reservation.sourceType === 'Pedido de compra' ? 'entries' : 'orders'}/${
            reservation.sourceId
          }`,
        );

        const orderDetails = response.data.order || response.data.entryOrder;
        setSelectedOrder(orderDetails);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.response.data.message || 'Ocorreu um erro ao buscar dados do pedido');
      }
    }
  };

  useEffect(() => {
    if (selectedOrder && selectedOrder.orderId) {
      setOpenOrderDetails(true);
    }
  }, [selectedOrder]);

  return (
    <>
      <SimpleDialog
        dialogTitle="Reservas de Estoque"
        openDialog={openDialogReservations}
        handleClose={handleCloseDialogReservations}
        content={
          <Box width={600}>
            <SimpleBackdrop loading={loading} />
            <Box position="relative" marginBottom={5}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Pedido nº</TableCell>
                        <TableCell align="center">Tipo de Pedido</TableCell>
                        <TableCell align="center">Quantidade</TableCell>
                        <TableCell align="center">Data do Pedido</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reservationsStock.map((reservation) => (
                        <TableRow
                          key={reservation._id}
                          onClick={() => handleSelectOrder(reservation)}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableCell align="center">{reservation.sourceId}</TableCell>
                          <TableCell align="center">{reservation.sourceType}</TableCell>
                          <TableCell align="center">
                            {reservation.items.map((item) =>
                              item.productId === productId ? item.quantity : null,
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {formatDateWithHour(reservation.createdAt)}
                          </TableCell>
                        </TableRow>
                      ))}
                      {reservationsStock.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Typography>Não há reservas deste produto</Typography>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        }
      />
      {openOrderDetails && selectedOrder.orderId && (
        <OrderDetails
          openOrderDetails={openOrderDetails}
          closeOrderDetails={closeOrderDetails}
          selectedOrder={selectedOrder}
        />
      )}
    </>
  );
}

export default ReservationStocks;
